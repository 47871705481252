








import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class PicWall extends Vue {
  @Prop() readonly pic!: string;
  @Prop({ default: "" }) readonly path!: string;

  goForward() {
    console.log(1111);

    this.$router.push({
      path: this.path,
    });
  }
}
