


















































import { Vue, Component } from "vue-property-decorator";
import Layout from "@/components/Layout.vue";
import AssignCard from "@/components/common/AssignCard.vue";
import PicWall from "@/components/common/PicWall.vue";

@Component({
  components: {
    Layout,
    AssignCard,
    PicWall,
  },
})
export default class society extends Vue {
  hbgsrc: string = require("@/assets/social/img/societybg.png");
  society1: string = require("@/assets/social/img/society1.png");
  society3: string = require("@/assets/social/img/society3.png");
  pic: string = require("@/assets/social/img/society2.png");
}
